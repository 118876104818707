<template>
  <div class="setting-page">
    <div class="ft20 cl-main ftw600">门店详情</div>
    <div class="mt20">
      <a-button type="primary" @click="addAct">新增门店</a-button>
    </div>
    <div class="mt20">
      <div class="wxb-table-white">
        <!-- 表格 -->
        <a-table :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading" :rowKey="record => record.mendian_id">
          <template slot="photo" slot-scope="record">
            <div>
              <img class="swiper_item" :src="record" alt="">
            </div>

          </template>
          <template slot="action" slot-scope="record">

            <div class="flex center">
              <a-dropdown placement="bottomRight">
                <span class="more-act">
                  <i class="iconfont iconmore_gray"></i>
                </span>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="editAct(record)">
                      <span>编辑</span>
                    </a>
                  </a-menu-item>

                  <a-menu-item>
                    <a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="delAct(record)">
                      <a class="menu-act" href="javascript:;">
                        <span>删除</span>
                      </a>
                    </a-popconfirm>

                  </a-menu-item>

                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <div>
      <a-modal title="编辑" :visible="showForm" @ok="handleOk" @cancel="handleCancel">
        <a-form-model :model="detail">
          <a-form-model-item required label="封面图">
            <a-upload name="file" :showUploadList="false" :customRequest="uploadImg" :beforeUpload="beforeUpload">

              <div class="flex_c" v-if="detail.photo">

                <img class="avatar" :src="detail.photo" alt="avatar" />
              </div>
              <a-button v-else> <a-icon type="upload" />点击上传</a-button>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: "",
      mendian_id: "",
      shop_id: "",
      showForm: false,
      columns: [
        { title: "工位号", dataIndex: "station_id", align: "center" },
        {
          title: "封面图",
          dataIndex: "photo",
          align: "center",
          scopedSlots: { customRender: "photo" },
        },
        {
          title: "工位状态",
          dataIndex: "status",
          align: "center",
          customRender: (text) => {
            if (text) {
              return text == 1 ? "空闲" : "占用";
            }
          },
        },
        { title: "开始时间", dataIndex: "begin_time", align: "center" },
        { title: "结束时间", dataIndex: "end_time", align: "center" },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      datas: [],
      detail: {},
      Addtype: false,
    };
  },
  created() {
    if (this.$route.params.itme?.mendian_id) {
      this.mendian_id = this.$route.params.itme.mendian_id;
      this.shop_id = this.$route.params.itme.shop_id;
      localStorage.setItem(
        "mend_datails",
        JSON.stringify(this.$route.params.itme)
      );
    } else {
      let item = localStorage.getItem("mend_datails");
      this.mendian_id = item.mendian_id;
      this.shop_id = item.shop_id;
    }

    this.getdata();
  },
  methods: {
    // 新增
    addAct() {
      this.Addtype = true;
      let value = JSON.parse(localStorage.getItem("mend_datails"));
      this.detail = {
        mendian_id: value.mendian_id,
        photo: "",
        shop_id: value.shop_id,
      };
      this.showForm = true;
    },
    // 修改保存
    handleOk() {
      let data = {
        photo: this.detail.photo,
        mendian_id: this.detail.mendian_id,
        shop_id: this.detail.shop_id,
      };
      if (!this.Addtype) {
        data.station_id = this.detail?.station_id;
      }
      this.$http
        .api("admin/saveGongwei", data)
        .then((res) => {
          this.$message.success("操作成功");
          this.showForm = false;
          this.detail = {};
          this.getdata();
          // this.pagination.total = res.total;
        })
        .catch((res) => {});
    },
    // 删除
    delAct(v) {
      this.$http
        .api("admin/deleteGongwei", {
          station_id: v.station_id,
        })
        .then((res) => {
          this.$message.success("操作成功");
          this.getdata();
        })
        .catch((res) => {});
    },
    handleCancel() {
      this.imageUrl = "";
      this.showForm = false;
    },
    // 修改
    editAct(res) {
      this.Addtype = false;
      this.detail = res;
      this.imageUrl = res.photo;
      this.showForm = true;
    },
    uploadImg(e) {
      let file = e.file;
      this.loading = true;
      this.$http
        .upload(file)
        .then((res) => {
          this.loading = false;
          this.detail.photo = res.url;
          // this.handleTableChange(1);
        })
        .catch((res) => {
          this.$message.error("上传图片出错了");
          this.loading = false;
        });
    },
    beforeUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isImg) {
        this.$message.error("只能允许上传图片");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片最大为2M");
        return false;
      }
      return isImg && isLt2M;
    },

    getdata() {
      this.$http
        .api("admin/gongweiIndex", {
          mendian_id: this.mendian_id,
          shop_id: this.shop_id,
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        })
        .then((res) => {
          this.datas = res;
          console.log(this.datas, "  this.datas");
          // this.pagination.total = res.total;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getdata();
    },
  },
};
</script>

<style>
.setting-page {
  background-color: #fff;
  padding: 20px;
}
.swiper_item {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}
.avatar {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}
.flex_c {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>